import React, { useState } from "react";

export const Tile = ({
  text,
  subText,
  image,
  height = "h-[249.98px]",

  className,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const subTextHeight = "100px"; // Fixed height for subtext

  return (
    <div
      className={`relative ${height} rounded-2xl overflow-hidden ${className}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Background Image with Brightness Adjustment */}
      <div
        className="absolute inset-0 w-full h-full bg-cover bg-center brightness-50"
        style={{ backgroundImage: `url(${image})` }}
      ></div>

      {/* Text Overlay */}
      <div className="absolute inset-0 p-5 flex flex-col justify-end">
        <p className="text-white text-[16px] font-cairo font-semibold mb-0 transition-transform duration-300 ease-in-out">
          {text}
        </p>

        {/* Sub Text - Rendered only when hovered, with a smooth height transition */}
        <div
          className={`overflow-hidden transition-all duration-500 ease-in-out`}
          style={{
            maxHeight: isHovered ? subTextHeight : "0px",
            opacity: isHovered ? 1 : 0,
          }}
        >
          <p
            className="text-white text-[14px] font-cairo"
            style={{
              transform: isHovered
                ? "translateY(0)"
                : `translateY(${subTextHeight})`,
              transition: "transform 0.5s ease-in-out",
            }}
          >
            {subText}
          </p>
        </div>
      </div>
    </div>
  );
};