import React from 'react';
import logoImage from '../assets/images/logo.png'; 

const Logo = () => {
  return (
    <a href="/">
      <img src={logoImage} alt="Logo" className="w-32 ml-3" />
    </a>
  );
};

export default Logo;