import React, { useState } from "react";
import { toast } from 'react-toastify';

const Footer = () => {
  const [email, setEmail] = useState(""); // State to store email input

  // Function to handle input change
  const handleInputChange = (event) => {
    setEmail(event.target.value); // Update state with user input
  };

  // Function to validate email format
  const validateEmail = (email) => {
    // Basic email pattern for validation
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };

  // Function to handle subscribe button click
  const handleSubscribe = (event) => {
    event.preventDefault();

    // Validate the email before proceeding
    if (!validateEmail(email)) {
      toast.success("Please enter a valid email address.");
      return;
    }

    // Adding a delay before showing the alert
    setTimeout(() => {
      toast.success("Email sent!");
      setEmail(""); // Reset the email input field
    }, 1000); // 2000ms = 2 seconds delay
  };

  return (
    <div className="flex flex-row bg-customGray2">
      <div className="flex mt-3 flex-col text-left">
        <h1 className="ml-4 text-2xl text-white font-cairo">Stay Informed</h1>
        <h2 className="ml-4 text-white font-cairo">
          Sign up to receive our latest news and ways to get involved
        </h2>
        <div className="flex flex-col md:flex-row pt-3 place-content-center">
          <input
            type="email"
            className="mx-3 p-2 w-96 rounded-2xl font-cairo text-customGray2 text-left"
            value={email}
            placeholder="Email Address"
            onChange={handleInputChange}
          />
          <button
            onClick={handleSubscribe}
            className="px-5 py-2 ml-4 mt-3 bg-blue-500 w-40  text-white rounded-2xl hover:bg-blue-600 transition duration-200"
          >
            Subscribe
          </button>
        </div>
      </div>
    </div>
  );
};

export default Footer;