import React, { useState, useEffect } from "react";
import Button from "./button/Button";

const Carousel = ({ items }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  // Automatically change the slide every 3 seconds
  useEffect(() => {
    if (!isPaused) {
      const timer = setInterval(() => {
        setCurrentIndex((prevIndex) =>
          prevIndex === items.length - 1 ? 0 : prevIndex + 1
        );
      }, 5000);
      return () => clearInterval(timer); // Clear timer on component unmount
    }
  }, [items.length, isPaused]);

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className="relative w-full h-[550px] md:h-[880px] overflow-hidden">
      <div
        className="absolute inset-0 flex transition-transform duration-500"
        style={{
          transform: `translateX(-${currentIndex * 100}%)`,
        }}
      >
        {items.map((item, index) => (
          <div
            key={index}
            className="flex-shrink-0 w-full h-full bg-cover bg-center relative"
            style={{ backgroundImage: `url(${item.image})` }}
          >
            {/* Gradient Overlay */}
            <div className="absolute inset-0 bg-gradient-to-r from-transparent via-black/50 to-transparent"></div>

            {/* Content on top of the image */}
            <div className="absolute inset-y-1/5 md:inset-y-1/3 pt-6 w-full px-4 md:px-6 text-white text-center md:text-left">
              <h2 className="text-xl md:text-3xl font-bold mb-4">{item.title}</h2>
              <p className="text-base md:text-lg mt-2">{item.description}</p>
              <div className="flex flex-col md:flex-row md:space-x-1 mt-6 justify-center md:justify-start">
                <Button
                  color="bg-white text-black"
                  navigateTo="/donate"
                  text="Donate Now"
                  setIsPaused={setIsPaused}
                />
                {currentIndex === 0 && (
                  <Button
                    videoId="ghwbb4Va1Zw"
                    color="bg-red-700 text-white py-2 px-4 rounded hover:bg-transparent hover:text-red-500 hover:border-red-500 border border-red-500 transition-all duration-300"
                    text="Watch Video"
                    setIsPaused={setIsPaused}
                  />
                )}
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Dots for navigation */}
      <div className="absolute bottom-4 left-0 right-0 flex justify-center space-x-2">
        {items.map((_, index) => (
          <button
            key={index}
            onClick={() => goToSlide(index)}
            className={`w-3 h-3 rounded-full ${
              currentIndex === index ? "bg-white" : "bg-gray-400"
            }`}
          ></button>
        ))}
      </div>
    </div>
  );
};

export default Carousel;