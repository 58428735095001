import React from 'react'
import nonProfitLogo from "../../assets/images/logo/nonprofit.png";
import bbbLogo from "../../assets/images/logo/bbb.png";
import bestAmericaLogo from "../../assets/images/logo/bestAmerica.png";
import cfcLogo from "../../assets/images/logo/cfc.png";
import charityNavigatorLogo from "../../assets/images/logo/charity-navigator.png";

const HtmlLogos = () => {

  const logos = [
    { logo: nonProfitLogo, link: "https://greatnonprofits.org/org/palestine-childrens-relief-fund" },
    { logo: bbbLogo, link: "https://www.bbb.org" },
    { logo: charityNavigatorLogo, link: "https://www.best-charities.org/find/charitypage.php?id=17&ein=93-1057665" },
    { logo: cfcLogo, link: "https://www.bestcfc.org/charitypage.php?ein=93-1057665" },
    { logo: bestAmericaLogo, link: "https://www.charitynavigator.org/?bay=search.summary&orgid=6421" }
  ];

  return (
    <div className='grid md:grid-cols-5 grid-cols-2 z-10 pt-9 gap-5'>
      {logos.map((logo, index) => (
        <a className='mx-2' key={index} href={logo.link} target="_blank" title='Efficiency And Transparency'>
          <img src={logo.logo} alt={`Logo ${index + 1}`} className="z-40" />
        </a>
      ))}
    </div>
  );
}

export default HtmlLogos;