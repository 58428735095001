import React, { useState } from 'react';
import image1 from "../../assets/images/mission/1.jpg";
import image2 from "../../assets/images/mission/2.jpg";
import image3 from "../../assets/images/mission/3.jpg";
import image4 from "../../assets/images/mission/4.jpg";
import image5 from "../../assets/images/mission/5.jpg";
import image6 from "../../assets/images/mission/6.jpg";
import image7 from "../../assets/images/mission/7.jpg";
import image8 from "../../assets/images/mission/8.jpg";
import image9 from "../../assets/images/mission/9.jpg";

const Tile = () => {
  const images = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9
  ];

  const [selectedImage, setSelectedImage] = useState(null);

  const closeModal = () => setSelectedImage(null);

  return (
    <div className="flex justify-center items-center h-[422px] w-[1200px] mx-auto">
    <div className="grid grid-rows-2 gap-2">
      {/* First Row: 5 tiles */}
      <div className="grid grid-cols-5 gap-2 ">
        {images.slice(0, 5).map((image, index) => (
          <div key={index} className="relative">
            <img
              src={image}
              alt={`Image ${index + 1}`}
              className="rounded-xl w-[201px] h-[201px] object-cover cursor-pointer"
              onClick={() => setSelectedImage(image)}
            />
          </div>
        ))}
      </div>

      {/* Second Row: 4 tiles */}
      <div className="grid grid-cols-4 gap-2 px-[95px]">
        {images.slice(5, 9).map((image, index) => (
          <div key={index} className="relative">
            <img
              src={image}
              alt={`Image ${index + 6}`}
              className="rounded-xl w-[201px] h-[201px] object-cover cursor-pointer"
              onClick={() => setSelectedImage(image)}
            />
          </div>
        ))}
      </div>

      {/* Modal for Image Preview */}
      {selectedImage && (
        <div
          className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center"
          onClick={closeModal}
        >
          <div className="relative">
            {/* Full-size image */}
            <img src={selectedImage} alt="Full Size" className="max-w-[90%] max-h-[90%]" />
            {/* Close button */}
            <button
              className="absolute top-2 right-2 text-white bg-gray-800 px-4 py-2 rounded-full"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
    </div>
  );
};

export default Tile;