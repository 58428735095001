import React from 'react';

const Button2 = ({ text, link }) => {
  const handleClick = (event) => {
    event.preventDefault(); // Prevent the default anchor link behavior

    // Optionally, navigate to the link if needed
    if (link) {
      window.location.href = link; // Navigate to the link after showing the alert
    }
  };

  return (
    <div className='place-self-center'>
      <button 
        onClick={handleClick} // Attach the click handler
        className='px-5 place-self-center py-2 bg-customBlueButton text-white hover:bg-white hover:text-customBlue border border-customBlueButton rounded-2xl duration-300'
      >
        {text}
      </button>
    </div>
  );
};

export default Button2;