import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ContactUs from "./pages/ContactUs";
import { Home } from "./pages/Home";

import Navbar from "./components/Navbar";
import Donate from "./pages/Donate";
import Footer from "./pages/Footer";
import Mission from "./pages/Mission";
import ThankYou from "./pages/ThankYou";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <ToastContainer position="top-center" autoClose={3000} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/mission" element={<Mission />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/donate" element={<Donate />} />
          <Route path="/thank-you" element={<ThankYou />} />
        </Routes>
        <Footer/>
      </div>
    </Router>
  );
}

export default App;
