import React from "react";
import mission from "../assets/images//mission/mission.png";
import eyeIcon from "../assets/vectors/eye.svg";
import globeIcon from "../assets/vectors/globe-icon.svg";
import Card from "../components/cards/Card";
import Tile2 from "../components/tiles/Tile2";
import YouTubeEmbed from "../components/video/YoutubeEmbed";
const Mission = () => {
  const cards = [
    {
      logo: eyeIcon,
      title: "OUR VISION",
      text: "We envision a world where all children in the Middle East have access to quality medical care, humanitarian aid, and specialized surgical procedures.",
    },
    {
      logo: globeIcon,
      title: "OUR MISSION",
      text: "Our mission is to provide medical and humanitarian relief collectively and individually to children throughout the Levant, regardless of their nationality or religion.",
    },
  ];

  return (
    <div>
      <div className="shrink-0 grid grid-cols-4 gap-4 header h-[100px] bg-gradient-to-r from-[#024064] to-[#016785] w-full">
        <h1 className="col-span-1 text-white mt-8 font-cairo text-[32px] font-bold text-2xl ">
          Who is PCRF?
        </h1>
      </div>
      <div>
        <div className="relative w-full h-full">
          <img className="w-full h-[600px] object-cover" src={mission} />
          <div class="absolute inset-0 bg-gradient-to-t  from-blue-600 from-10% to-transparent to-40% opacity-80"></div>
        </div>
      </div>
      <h1 className="mt-6 text-customBlue font-cairo font-bold text-[24px]">
        The PCRF
      </h1>
      <div className="shrink-0 tracking-wider text-customGray2 px-[30px] font-cairo text-center">
        <p className="shrink-0 justify-center">
          Palestine Children's Relief Fund (PCRF), founded in 1991 by concerned
          humanitarians in the USA, provides free medical care to thousands of
          injured and ill children yearly who lack local access to care within
          the local health care system. Over the years, we've sent over 2,000
          affected children abroad for free medical care, sent thousands of
          international doctors and nurses to provide tens of thousands of
          children free medical care in local hospitals, and provided tens of
          thousands of children humanitarian aid and support they otherwise
          would not get.
        </p>

        <p>
          PCRF's impact also includes establishing two pediatric cancer
          departments in Palestine, a new PICU and pediatric cardiology
          department in Ramallah, and many more critical projects to sustainably
          bolster the region's healthcare system. Our foundation relies on
          grassroots efforts, supported by a worldwide network of thousands of
          volunteers, to fulfill our humanitarian mission. We are a
          volunteer-based nonprofit, so please join our efforts.
        </p>
      </div>
      <div className="flex place-content-center space-x-5  mt-9 h-[252px]">
        <Card
          icon={cards[0].logo}
          title={cards[0].title}
          text={cards[0].text}
        ></Card>
        <Card
          icon={cards[1].logo}
          title={cards[1].title}
          text={cards[1].text}
        ></Card>
      </div>
      <div className="flex flex-row place-content-center  h-[360px] bg-cover">
       <YouTubeEmbed videoId="5-C1YnFQPOg" />
      </div>
    <div className="h-[422px] w-full">
<Tile2/>
    </div>
    </div>
  );
};

export default Mission;
