import React from "react";
import { Link } from "react-router-dom";
import Logo from "./Logo";
const Navbar = () => {
  const links = [
    { href: "/mission", text: "OUR MISSION AND VISION" },
    { href: "/contact-us", text: "CONTACT US" },
    { href: "/donate", text: "DONATE" },
  ];
  return (
    <div className="flex flex-row place-content-center">
    <nav className="bg-white p-4">
      <Logo/>
      <ul className="flex flex-row place-content-end space-x-5 w-full font-bold ">
        {links.map((link, index) => (
          <li key={index}>
            <Link to={link.href}  className="text-customBlue hover:text-gray-400" >
              {link.text}
              </Link>
          </li>
        ))}
      </ul>
    </nav>
    </div>
        )};

export default Navbar;
