import React from 'react'

import image1 from "../../assets/images/image1.png";
import image2 from "../../assets/images/image2.png";
import image3 from "../../assets/images/image3.png";
import image4 from "../../assets/images/image4.png";
import image5 from "../../assets/images/image5.png";
import image6 from "../../assets/images/image6.png";
import image7 from "../../assets/images/image7.png";
import image8 from "../../assets/images/image8.png";
import image9 from "../../assets/images/image9.png";
import image10 from "../../assets/images/image10.png";
import image11 from "../../assets/images/image11.png";

const TileProps = () => {
    const tiles = [
        {
          text: "Dr. Musa and Suhaila Nasir Pediatric Cancer Department",
          image: image1 
        },
        {
          text: "Pediatric Cardiac Surgery Program",
          image:  image2 ,
        }, {
          text: "Gaza Pediatric Mental Health Initiative",
          image:  image3 ,
        }, {
          text: "Gaza Orphan Sponsorship Program",
          image: image4 ,
        }, {
          text: "Gaza Amputee Project",
          image: image5 ,
        }, {
          text: "Humanitarian Projects",
          image:  image6 ,
        }, {
          text: "Hospital Infrastructure Projects",
          image:  image7 ,
        }, {
          text: "Huda Al Masri Pediatric Cancer Department",
          image:  image8,
        }, {
          text: "Medical Missions",
          image:  image9 ,
        }, {
          text: "Humanitarian Programs",
          image: image10 ,
        }, {
          text: "Treatment Abroad Program",
          image: image11 
        }
      ];
 return tiles;
}

export default TileProps