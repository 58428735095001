import React from "react";
import image1 from "../../assets/images/card/card1.png";
import image2 from "../../assets/images/card/card2.png";
import image3 from "../../assets/images/card/card3.png";
import image4 from "../../assets/images/card/card4.png";
import image5 from "../../assets/images/card/card5.png";
import image6 from "../../assets/images/card/card6.png";
import image7 from "../../assets/images/card/card7.png";
import image8 from "../../assets/images/card/card8.png";

const Card2Props = () => {
  const cards = [
    {
      image: image1,
      title: "Successful Evacuation of Children from Gaza to Ita...",
      text: "The PCRF Treatment Abroad Team has successfully evacuated eight children from Gaza to Italy for medical treatment, accom... ",
    },
    {
      image: image2,
      title: "Gratitude for International Support: Gaza Patients...",
      text: "We’re filled with gratitude as we share that our brave patients who arrived in Spain and Belgium are now receiving... ",
    },
    {
      image: image3,
      title: "Central Gaza Field Hospital Nears Completion",
      text: "We are nearing the completion of our second Field Hospital in central Gaza, a pivotal development in partnership with th... ",
    },
    {
      image: image4,
      title: "Eyeglasses for Displaced Children",
      text: "Our PCRF team’s unwavering dedication has culminated in the successful implementation of the ‘One-Time Spons... ",
    }, {
      image: image5,
      title: "Medical Care for Displaced Families",
      text: "In the midst of relentless conflict and blockade, our PCRF teams remain steadfast, delivering critical food parcels to d... ",
    }, {
      image: image6,
      title: "Food Parcel Distribution for Displaced Families",
      text: "In the midst of relentless conflict and blockade, our PCRF teams remain steadfast, delivering critical food parcels to d... ",
    }, {
      image: image7,
      title: "Diaper and Infant Formula Distribution for Displac...",
      text: "We at PCRF continue our mission of serving humanity by distributing hundreds of diaper packs and infant formula to displ... ",
    }, {
      image: image8,
      title: "Vegetable Distribution for Displaced Families",
      text: "In the face of ongoing conflict, our teams at the Palestine Children’s Relief Fund (PCRF) are tirelessly aiding di... ",
    },
  ];
  return cards;
};

export default Card2Props;
