import React from "react";
import { useNavigate } from "react-router-dom";


function Button({ text }) {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/donate");
  };

  return (
    <button
    onClick={handleClick}
    className="p-2 rounded-md text-white bg-customYellow">
      {text}
    </button>
  );
}

export default Button;
