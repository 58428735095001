import React from "react";

function TintedImage({ imageSrc, overlayColorClass = "bg-customOverlay", children }) {
  return (
    <div className="relative md:h-[300px] md:w-[300px] bg-cover bg-center rounded-lg" 
      style={{
        backgroundImage: `url(${imageSrc})`,
      }}
    >
      {/* Overlay with tint */}
      <div
        className={`absolute inset-0 rounded-lg opacity-50 ${overlayColorClass}`}
      />

      {/* Optional content (e.g., text) */}
      <div className="absolute inset-0 flex items-center justify-center">
        {children}
      </div>
    </div>
  );
}

export default TintedImage;