import React from 'react'
import { useNavigate } from "react-router-dom";

const Card2 = ({ image, title, text }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/mission");
  };
  return (
    <div className='group flex flex-col bg-white border rounded-lg border-gray-500 mb-5 h-[381px] w-[292px] transition-shadow duration-300 ease-in-out hover:shadow-2xl'>
      <img className='h-[185px] rounded-t-lg' src={image} alt={title} />
      <div className="items-center justify-center w-[262px] h-[166px]">
        <div className='relative p-3 pb-2 mt-3 font-cairo'>
          <p className="leading-none mb-2 text-customGray2 text-[16px] font-semibold">{title}</p>
          <p className="leading-none tracking-widest text-[#444444] text-[14px]">
            {text}
            <button onClick={handleClick} className='text-customYellow font-semibold ml-2'>Read more</button>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Card2