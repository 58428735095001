import React from "react";
import Button from "./Button";
import { Icon } from "@iconify/react";
import instagramIcon from "@iconify-icons/mdi/instagram";
import facebookIcon from "@iconify-icons/mdi/facebook";
function SocialNavbar() {
  return (
    <div className="flex flex-row place-content-end gap-5 width-1916 bg-customBlue h-[50]">
      <a
        href="https://www.facebook.com/ThePCRF/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Icon
          icon={facebookIcon}
          className="text-3xl  text-white hover:text-blue-700 transition duration-200"
        />
      </a>
      <a
        href="https://www.instagram.com/thepcrf/?hl=en"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Icon
          icon={instagramIcon}
          className="text-3xl  text-white hover:text-blue-700 transition duration-200"
        />
      </a>
      <Button className="" text="Donate" />
    </div>
  );
}

export default SocialNavbar;
