import React from "react";

const ProgressBar = ({ raisedAmount, goalAmount, percentageRaised, donors }) => {
  // Calculate the percentage of the goal reached
  const progressPercentage = (raisedAmount / goalAmount) * 100;

  return (
    <div className="mt-4 max-w-lg mx-auto bg-white p-4 rounded-lg shadow-md">
      <div className="flex justify-between items-center">
        <p className="text-2xl font-bold text-gray-800">
          ${raisedAmount.toLocaleString()} <span className="text-sm text-gray-500">Raised</span>
        </p>
        <p className="text-2xl font-bold text-gray-800">
          Goal ${goalAmount.toLocaleString()}
        </p>
      </div>
      
      {/* Progress Bar */}
      <div className="w-full bg-gray-200 rounded-full h-4 my-4">
        <div
          className="bg-blue-600 h-full rounded-full"
          style={{ width: `${progressPercentage}%` }}
        ></div>
      </div>

      {/* Percentage Raised and Number of Donors */}
      <div className="flex justify-between text-sm text-gray-600">
        <p>{percentageRaised}% Raised</p>
        <p>{donors.toLocaleString()} Donors</p>
      </div>
    </div>
  );
};

export default ProgressBar;