import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import YouTubeEmbed from "../../video/YoutubeEmbed";

const Button = ({ text, color, videoId, navigateTo, setIsPaused }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    if (navigateTo) {
      navigate(navigateTo); // Navigate if navigateTo is provided
    } else if (videoId) {
      setIsPaused && setIsPaused(true); // Pause the carousel (if applicable)
      setIsModalOpen(true); // Open video modal if videoId is provided
    }
  };

  const handleCloseModal = () => {
    setIsPaused && setIsPaused(false); // Resume the carousel (if applicable)
    setIsModalOpen(false); // Close the modal
  };

  return (
    <div>
      <button
        onClick={handleClick}
        className={`${color} font-bold m-2 text-black border border-transparent hover:bg-transparent hover:text-white hover:border-white py-2 px-4 rounded transition-all duration-300`}
      >
        {text}
      </button>

      {isModalOpen && videoId && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
          <div className="relative w-full h-full flex justify-center items-center">
            <div className="w-[90%] h-[90%] max-w-6xl">
              <YouTubeEmbed videoId={videoId} />
            </div>

            {/* Close Button */}
            <button
              className="absolute top-4 right-4 text-white text-2xl"
              onClick={handleCloseModal}
            >
              &times;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Button;