import React from "react";
import SocialNavbar from "../components/SocialNavbar";
import Carousel from "../components/carousel/Carousel";
import Card from "../components/cards/Card";
import eyeIcon from "../assets/vectors/eye.svg";
import globeIcon from "../assets/vectors/globe-icon.svg";
import { Tile } from "../components/tiles/Tile";
import TileProps from "../components/tiles/TileProps";
import Card2 from "../components/cards/Card2";
import Card2Props from "../components/cards/Card2Props";
import CarouselProps from "../components/carousel/CarouselProps";
import HtmlLogos from "../components/logo/HtmlLogos";
import rings from "../assets/images/rings.png";
import rating from "../assets/images/rating.png";
import Button2 from "../components/carousel/button/Button2";
import TintedImage from "../components/TintedImage";
export const Home = () => {
  const tiles = TileProps();
  const cards2 = Card2Props();
  const carousel = CarouselProps();

  const cards = [
    {
      logo: eyeIcon,
      title: "OUR VISION",
      text: "We envision a world where all children in the Middle East have access to quality medical care, humanitarian aid, and specialized surgical procedures.",
    },
    {
      logo: globeIcon,
      title: "OUR MISSION",
      text: "Our mission is to provide medical and humanitarian relief collectively and individually to children throughout the Levant, regardless of their nationality or religion.",
    },
  ];
  return (
    <div className="flex flex-col w-full">
      <SocialNavbar />
      <Carousel items={carousel} />
      <div className="mx-3 flex place-content-center space-x-3  mt-9 h-[252px]">
        <Card
          icon={cards[0].logo}
          title={cards[0].title}
          text={cards[0].text}
        ></Card>
        <Card
          icon={cards[1].logo}
          title={cards[1].title}
          text={cards[1].text}
        ></Card>
      </div>
      <div className="text-center bg-customGray flex flex-col text-customBlue ">
        <h1 className="font-cairo font-bold text-lg m-6">
          Act Now: Your Donation Changes Lives
        </h1>
        <div className="flex flex-col text-left items-center place-content-center mb-5">
          <p className="mb-2 text-gray-700 text-[17px] p-3">
            Your contribution is more than a donation; it's a lifeline for these
            vulnerable children. It means access to medical care, mental health
            support, and essential supplies..
          </p>

          <Button2 link="/donate" text="DONATE NOW" />
        </div>
      </div>
      <h1 className="text-customBlue my-5 text-center font-cairo font-bold text-2xl">
        Our Humanitarian Programs and Projects
      </h1>
      <div className="mx-3  grid md:grid-cols-3 grid-cols-1 gap-4">
        <Tile
          width=""
          height="h-[350px]"
          text={tiles[0].text}
          subText="Gaza's Only Pediatric Cancer Department"
          image={tiles[0].image}
          className="md:col-span-2 col-span-3"
        ></Tile>
        <Tile
          width=""
          height="h-[350px]"
          text={tiles[1].text}
          subText="There are hundreds of children born in the Gaza Strip each year with congenital heart disease which cannot be treated locally"
          image={tiles[1].image}
          className="md:col-span-1 col-span-3"
        ></Tile>
        <Tile
          text={tiles[2].text}
          subText="This Gaza-only initiative was organized as an early response to the war in 2014"
          image={tiles[2].image}
          className="md:col-span-1 col-span-3"
        ></Tile>
        <Tile
          text={tiles[3].text}
          subText="We established a humanitarian program to ensure that the livelihood of orphaned children in Gaza are being met"
          image={tiles[3].image}
          className="md:col-span-1 col-span-3"
        ></Tile>
        <Tile
          text={tiles[4].text}
          subText="In 2019, PCRF began a project to help support the care for children in Gaza suffering from congenital or traumatic limb amputations"
          image={tiles[4].image}
          className="md:col-span-1 col-span-3"
        ></Tile>
        <Tile
          text={tiles[5].text}
          subText="Humanitarian Projects"
          image={tiles[5].image}
          className="md:col-span-1 col-span-3"
        ></Tile>
        <Tile
          text={tiles[6].text}
          subText="Hospital Infrastructure Projects"
          image={tiles[6].image}
          className="md:col-span-2 col-span-3"
        ></Tile>
        <Tile
          text={tiles[7].text}
          subText="The First And Only Public Department For Children With Cancer In Palestine"
          image={tiles[7].image}
          subTextHeight="70px"
          className="md:col-span-2 col-span-3"
        ></Tile>
        <Tile
          text={tiles[8].text}
          subText="The PCRF is the main nonprofit in the world sponsoring and running international volunteer medical teams to work in public hospitals..."
          image={tiles[8].image}
          className="md:col-span-1 col-span-3"
        ></Tile>
        <Tile
          text={tiles[9].text}
          subText="PCRF continues to provide a variety of impactful humanitarian programs for children in Gaza, the West Bank, Lebanon, and Jordan, regardless of their nationality or religion..."
          image={tiles[9].image}
          className="col-span-3 md:col-span-1"
        ></Tile>
        <Tile
          text={tiles[10].text}
          subText="Treatment Abroad Program"
          image={tiles[10].image}
          className="col-span-3 md:col-span-2"
        ></Tile>
      </div>
      <div className="pt-8 bg-customGray px-4 flex flex-col ">
        <h1 className="text-center font-cairo font-bold mb-6 text-customBlue text-[26px] ">
          Your Impact at Work
        </h1>

        <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 md:gap-3 gap-x-5 grid-cols-1 place-self-center">
          {cards2.map((card, index) => (
            <Card2
              key={index}
              image={card.image}
              title={card.title}
              text={card.text}
            />
          ))}
        </div>
      </div>
      <div className="flex pb-4  justify-center items-center pt-2">
        <div className="flex bg-customBlue border rounded-md justify-center items-center text-center  w-[224.84px] h-[47px]">
          <button className="text-white">See All Achievements</button>
        </div>
      </div>
      <div className="flex md:flex-row flex-col px-3 bg-customGreen pt-5">
        <div className="md:m-20 text-left">
          <h1 className="text-customBlue font-cairo font-bold text-lg">
            Efficiency And Transparency
          </h1>
          <p className="font-cairo text-[16px] font-bold text-slate-600">
            We are proud to be one of the most respected and active nonprofit
            humanitarian medical relief organizations working in the Middle
            East. For twelve straight years, we have received Charity
            Navigators’ coveted 4-star rating, demonstrating strong financial
            health and commitment to accountability and transparency. Only 3% of
            charities evaluated have received this rating
          </p>
          <div className="html-logos place-content-center">
          <HtmlLogos />
        </div>
        </div>


        <div className="my-10 flex flex-row bottom-0 z-20">
          <img className="md:scale-[1.2]  md:scale-x-150" src={rating}></img>
        </div>
        <div className="z-0 bg-customBlue3 flex flex-row place-items-center">
          
          <div className="inset-0  opacity-40 rounded-lg"></div>
          <TintedImage
            imageSrc={rings}
            overlayColorClass="bg-customBlue3"
          ></TintedImage>
        
        </div>
        
      </div>
    </div>
  );
};
