import React, { useState } from "react";
import location from "../assets/icons/location_on.svg";
import mail from "../assets/icons/mail.svg";
import phone from "../assets/icons/call.svg";
import fax from "../assets/icons/fax.svg";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "emailjs-com";
import { toast } from 'react-toastify';


const ContactUs = () => {
  const templateId = process.env.REACT_APP_TEMPLATE_ID;
  const serviceId = process.env.REACT_APP_SERVICE_ID;
  const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  const userId = process.env.REACT_APP_USER_ID;

  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    content: "",
  });

  // Handle reCAPTCHA verification
  const handleCaptcha = (value) => {
    console.log("Captcha value: ", value);
    if (value) {
      setCaptchaVerified(true);
    }
  };

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();

    if (!captchaVerified) {
      toast.info("Please complete the reCAPTCHA");
      return;
    }

    emailjs
      .send(
        serviceId,
        templateId,
        {
          from_name: formData.fullName,
          user_email: formData.email,
          user_phone: formData.phone,
          message: formData.content,
        },
        userId
      )
      .then(
        (result) => {
          toast.success("Message sent successfully!");
        },
        (error) => {
          toast.error.error("Email sending failed:", error.text);
        }
      );
  };

  return (
    <div className="bg-white min-h-screen items-center flex flex-col">
      {/* Header Section */}
      <div className="grid grid-cols-4 gap-4 h-[100px] bg-gradient-to-r from-[#024064] to-[#016785] w-full">
        <h1 className="col-span-2 text-white mt-8 font-cairo text-[32px] font-bold text-2xl">
          Contact Us
        </h1>
      </div>

      {/* Main Content */}
      <div className="max-w-4xl w-full bg-white shadow-md rounded-lg p-8 flex flex-col md:flex-row">
        {/* Left Side: Contact Form */}
        <div className="w-full md:w-1/2 pr-0 md:pr-8">
          <h2 className="text-2xl font-cairo font-bold text-[#016785] mb-4">
            Get In Touch With Us
          </h2>
          <form className="space-y-4" onSubmit={handleSubmit}>
            <div>
              <input
                type="text"
                name="fullName"
                placeholder="Full name"
                className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={formData.fullName}
                onChange={handleInputChange}
                required
              />
            </div>
            <div>
              <input
                type="email"
                name="email"
                placeholder="E-mail"
                className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
            </div>
            <div>
              <input
                type="tel"
                name="phone"
                placeholder="Phone"
                className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={formData.phone}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <textarea
                name="content"
                placeholder="Content"
                className="w-full p-3 border rounded-lg h-32 resize-none focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={formData.content}
                onChange={handleInputChange}
                required
              ></textarea>
            </div>
            <div>
              <div className="bg-gray-200 h-20 flex items-center justify-center rounded-lg mb-4">
                <ReCAPTCHA sitekey={siteKey} onChange={handleCaptcha} />
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="w-full bg-blue-600 text-white py-3 rounded-lg font-bold hover:bg-blue-700 transition duration-200"
              >
                SEND
              </button>
            </div>
          </form>
        </div>

        {/* Right Side: Contact Information */}
        <div className="w-full md:w-1/2 mt-8 md:mt-0">
          <h2 className="text-2xl font-cairo font-bold text-[#016785] mb-4">
            Contact Info
          </h2>
          <div className="flex flex-col space-y-1">
            <div className="flex items-start space-x-2">
              <img src={location} alt="location icon" className="mr-2" />
              <p>Ramallah, West Bank, Palestine</p>
            </div>
            <div className="flex items-start space-x-2">
              <img src={phone} alt="phone icon" className="mr-2" />
              <p>(970) 2-298-9293</p>
            </div>
            <div className="flex items-start space-x-2">
              <img src={fax} alt="fax icon" className="mr-2" />
              <p>(970) 2-296-3947</p>
            </div>
            <div className="flex items-start space-x-2">
              <img src={mail} alt="mail icon" className="mr-2" />
              <p>pcrf-rescue.net</p>
            </div>
          </div>

          <div className="flex flex-col mt-10 space-y-1">
            <div className="flex items-start space-x-2">
              <img src={location} alt="location icon" />
              <p>United States</p>
            </div>
            <div className="flex items-start space-x-2">
              <img src={phone} alt="phone icon" />
              <p>330-678-2645</p>
            </div>
            <div className="flex items-start space-x-2">
              <img src={fax} alt="fax icon" />
              <p>330-678-2661</p>
            </div>
            <div className="flex items-start space-x-2">
              <img src={mail} alt="mail icon" />
              <p>
                PO Box 861716 Los Angeles, CA 90086 (Kindly send check donations
                here)
              </p>
            </div>
            <div className="flex items-start space-x-2">
              <img src={mail} alt="mail icon" />
              <p>For media inquiries: media@pcrf-rescue.net</p>
            </div>
            <div className="flex items-start">
              <img src={mail} alt="mail icon" />
              <p className="-ml-6">
                For donation inquiries: giving@pcrf-rescue.net (EIN: 93-1057665)
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;