import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { toast } from 'react-toastify';

import {
  Elements,
  useStripe,
  useElements,
  CardElement,
} from "@stripe/react-stripe-js";
import ProgressBar from "../components/ProgressBar";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY); 

// List of countries with ISO 3166-1 alpha-2 codes
const countries = [
  { name: "United States", code: "US" },
  { name: "Canada", code: "CA" },
  { name: "United Kingdom", code: "GB" },
  { name: "Australia", code: "AU" },
  { name: "India", code: "IN" },
  { name: "Brazil", code: "BR" },
  { name: "Germany", code: "DE" },
  { name: "France", code: "FR" },
  { name: "Japan", code: "JP" },
  { name: "Mexico", code: "MX" },
  { name: "China", code: "CN" },
  { name: "Italy", code: "IT" },
  { name: "Spain", code: "ES" },
  { name: "Russia", code: "RU" },
  { name: "South Korea", code: "KR" },
  { name: "South Africa", code: "ZA" },
  { name: "Argentina", code: "AR" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Turkey", code: "TR" },
  { name: "Netherlands", code: "NL" },
  { name: "Switzerland", code: "CH" },
  { name: "Sweden", code: "SE" },
  { name: "Poland", code: "PL" },
  { name: "Belgium", code: "BE" },
  { name: "Indonesia", code: "ID" },
  { name: "Thailand", code: "TH" },
  { name: "Philippines", code: "PH" },
  { name: "Vietnam", code: "VN" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "Nigeria", code: "NG" },
  { name: "Israel", code: "IL" },
  { name: "Egypt", code: "EG" },
  { name: "Malaysia", code: "MY" },
  { name: "Norway", code: "NO" },
  { name: "Ireland", code: "IE" },
  { name: "New Zealand", code: "NZ" },
  { name: "Greece", code: "GR" },
  { name: "Portugal", code: "PT" },
  { name: "Pakistan", code: "PK" },
  { name: "Bangladesh", code: "BD" },
  { name: "Denmark", code: "DK" },
  { name: "Hungary", code: "HU" },
  { name: "Finland", code: "FI" },
  { name: "Romania", code: "RO" },
  { name: "Chile", code: "CL" },
  { name: "Colombia", code: "CO" },
  { name: "Peru", code: "PE" },
  { name: "Austria", code: "AT" },
  { name: "Czech Republic", code: "CZ" },
];

const Donate = () => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [clientSecret, setClientSecret] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [step, setStep] = useState(1);
  const [customAmount, setCustomAmount] = useState("");
  const amounts = [25, 50, 200, 500, 1000];
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    country: "",
    billingZip: "",
  });

  useEffect(() => {
    const amount = selectedAmount === "other" ? customAmount : selectedAmount;

    if (step === 3 && selectedAmount) {
      // Fetch the clientSecret from the backend
      fetch(`${process.env.REACT_APP_API_URL}/donate`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          selectedAmount: amount,
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
        }),
      })
        .then(res => res.json())
        .then(data => setClientSecret(data.clientSecret))
        .catch(error => console.error('Error fetching client secret:', error));
    }
  }, [step, selectedAmount,customAmount]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCountryChange = (e) => {
    setFormData({ ...formData, country: e.target.value });
  };
  const handleAmountClick = (amount) => {
    setSelectedAmount(amount === "other" ? "other" : amount);
    setCustomAmount("");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements || !clientSecret) return;

    setIsProcessing(true);
    const cardElement = elements.getElement(CardElement);

    const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: `${formData.firstName} ${formData.lastName}`,
          email: formData.email,
          address: {
            country: formData.country,
            postal_code: formData.billingZip,
          },
        },
      },
    });

    if (error) {
      console.error("Payment failed", error);
      toast.error("Payment failed. Please try again.");
    } else if (paymentIntent.status === "succeeded") {
      console.log("Payment successful", paymentIntent);
      toast.success("Payment successful!");
      navigate("/thank-you");
    }

    setIsProcessing(false);
  };

  return (
    <div className="h-[972px] flex flex-col items-center w-full box-border">
      {/* Step Tabs */}
      <div className="flex justify-center mb-4">
        <div className={`px-4 py-2 border-b-4 ${step === 1 ? "border-blue-500" : "border-gray-300"}`}>
          1. Amount
        </div>
        <div className={`px-4 py-2 border-b-4 ${step === 2 ? "border-blue-500" : "border-gray-300"}`}>
          2. Your Information
        </div>
        <div className={`px-4 py-2 border-b-4 ${step === 3 ? "border-blue-500" : "border-gray-300"}`}>
          3. Payment Details
        </div>
      </div>

      <div className="shadow-[0px_1px_3px_0px_rgba(47,73,117,0.2),0px_2px_2px_0px_rgba(47,73,117,0.12),0px_0px_2px_0px_rgba(47,73,117,0.14)] rounded-[4px] border-[1px_solid_#E6E9EE] bg-[#FFFFFF] m-[0_48px_0_0] flex flex-col p-[20px_1px_20px_0] w-[440px] h-auto box-sizing-border px-10 py-5">
        {step === 1 && (
          <div>
            <span className="font-cairo text-customGray2 font-bold uppercase mb-4">Amount</span>
            <div className="grid grid-cols-3 gap-2 mb-4">
              {amounts.map((amount, index) => (
                <button
                  key={index}
                  className={`rounded border-[1px_solid_#C0C8D7] bg-[#E7EBF7] p-2 text-center font-cairo font-medium cursor-pointer hover:bg-blue-200 ${
                    selectedAmount === amount ? "bg-blue-500 text-white" : ""
                  }`}
                  onClick={() => handleAmountClick(amount)}
                >
                   ${amount}
                </button>
              ))}
              <button
                className={`rounded border-[1px_solid_#C0C8D7] bg-[#E7EBF7] p-2 text-center font-cairo font-medium cursor-pointer hover:bg-blue-200 ${
                  selectedAmount === "other" ? "bg-blue-500 text-white" : ""
                }`}
                onClick={() => handleAmountClick("other")}
              >
                Other Amount
              </button>
            </div>
            {selectedAmount === "other" && (
              <input
                type="number"
                className="w-full p-2 border rounded"
                placeholder="Enter custom amount"
                value={customAmount}
                onChange={(e) => setCustomAmount(e.target.value)}
              />
            )}
            <button onClick={() => setStep(2)} className="bg-blue-500 text-white py-2 px-4 rounded w-full">
              Next
            </button>
          </div>
        )}

        {step === 2 && (
          <div>
            <h2 className="font-cairo text-customGray2 font-bold uppercase mb-4">Your Information</h2>
            <input
              type="text"
              name="firstName"
              placeholder="First Name"
              className="mb-2 p-2 w-full border rounded"
              value={formData.firstName}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="lastName"
              placeholder="Last Name"
              className="mb-2 p-2 w-full border rounded"
              value={formData.lastName}
              onChange={handleInputChange}
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              className="mb-2 p-2 w-full border rounded"
              value={formData.email}
              onChange={handleInputChange}
            />
            <select
              name="country"
              value={formData.country}
              onChange={handleCountryChange}
              className="mb-4 p-2 w-full border rounded"
            >
              <option value="">Select Country</option>
              {countries.map((country) => (
                <option key={country.code} value={country.code}>
                  {country.name}
                </option>
              ))}
            </select>
            <div className="flex justify-between">
              <button onClick={() => setStep(1)} className="bg-gray-300 text-black py-2 px-4 rounded">
                Back
              </button>
              <button onClick={() => setStep(3)} className="bg-blue-500 text-white py-2 px-4 rounded">
                Next
              </button>
            </div>
          </div>
        )}

        {step === 3 && (
          <div>
            <h2 className="font-cairo text-customGray2 font-bold uppercase mb-4">Payment Details</h2>
            <CardElement className="mb-4 p-2 border rounded" />
            <div className="flex justify-between">
              <button onClick={() => setStep(2)} className="bg-gray-300 text-black py-2 px-4 rounded">
                Back
              </button>
              <button type="submit" disabled={!stripe || isProcessing} onClick={handleSubmit} className="bg-blue-500 text-white py-2 px-4 rounded">
                Submit Payment
              </button>
            </div>
          </div>
        )}
      </div>
      <ProgressBar
        raisedAmount={32885948.05}
        goalAmount={35000000}
        percentageRaised={94}
        donors={99748}
      />
    </div>
  );
};

const App = () => (
    <Elements stripe={stripePromise}>
      <Donate />
    </Elements>
  );

export default App;