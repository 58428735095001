import React from 'react'

const Card = ({icon, title, text}) => {
  return (
    <div className="rounded-lg h-[230px] md:h-[194px] w-[555px] bg-gradient-to-r from-[#043B5C] to-[#0068A7]">
        <div>
            <div className='flex space-x-1 mt-9'>
            <div className="ml-3 h-[45px] w-[45px] bg-white rounded-full">
                <img src={icon} className="w-7 h-10 ml-5"/>
                </div>
            <div className='p-1 text-white'>{title}</div>
            </div>
            </div>
            <div className="m-5 text-xs md:text-base text-white">{text}</div>
        </div>
  )
}

export default Card